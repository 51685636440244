.bottomMenu-container {
    display: flex;
    flex-direction: row;
    .bottomMenu-leftContent {
        align-self: flex-start;
        margin-left: 10px;
        margin-right: auto;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
    }
    .bottomMenu-rightContent {
        align-self: flex-end;
        margin-right: 10px;
        margin-left: auto;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
        .buddy-topBarControlBox {
            font-weight: 900;
            cursor: pointer;
            margin-left: 6px;
            margin-right: 6px;
        }
        .buddy-topBarControlBox:hover {
            font-weight: 900;
            text-shadow: 0 0 7px white, 0 0 15px white;
            transition: 0.6s;
        }
    }
}

.dropUp {
    @media screen and (max-width: 576px) {
        display: content;
    }
    position: relative;
    display: inline-block;
  }
  
  .dropUpContent {
    display: none;
    position: absolute;
    bottom: 100%;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropUpContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropUpContent a:hover {
    background-color: #ddd;
  }
  
  .dropUp:hover .dropUpContent {
    display: block;
  }

.theme-button {
    max-width: 300px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    background-color: var(--primary-accent);
    color: var(--primary-color);
    text-shadow: 1px 1px 1px #000;
}