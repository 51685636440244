
.resume-container {
    color: #00c1da;
    padding: 8px;
    background-color: rgb(10, 9, 9);
}

a {
    color: #00c1da;
    text-emphasis: none;
}

h1, h2, h3 { 
    margin-block-end: 0;
    margin-block-start: 0; 
}
p {
    margin-block-end: 0;
    margin-block-start: 0; 
}

@import url('https://fonts.googleapis.com/css2?family=Radio+Canada&display=swap');
  
h1, h2, h3, p {
    font-family: 'Radio Canada', sans-serif;
}