body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #00c1da;
  --primary-accent: #ffffff;
  --primary-standout: rgb(103, 103, 146);
  --filter-var: invert(48%) sepia(54%) saturate(2547%) hue-rotate(152deg) brightness(106%) contrast(101%);
  --drop-filter-var: #00c1da;
}

.default-theme {
  --primary-color: #00c1da;
  --primary-accent: #ffffff;
  --primary-standout: rgb(103, 103, 146);
  --filter-var: invert(48%) sepia(54%) saturate(2547%) hue-rotate(152deg) brightness(106%) contrast(101%);
  --drop-filter-var: #00c1da;
}

.bachira-theme {
  --primary-color: #ffcc66;
  --primary-accent: #7c5300;
  --primary-standout: #7c5300;
  --filter-var: invert(97%) sepia(26%) saturate(3483%) hue-rotate(310deg) brightness(104%) contrast(106%);
  --drop-filter-var: #7c5300;
}

.karma-theme {
  --primary-color: #ff3300;
  --primary-accent: #330000;
  --primary-standout: #FFFFFF;
  --filter-var: invert(37%) sepia(97%) saturate(4938%) hue-rotate(360deg) brightness(99%) contrast(110%);
  --drop-filter-var: #330000;
}

.milim-theme {
  --primary-color: #ffcac6;
  --primary-accent: #d8797d;
  --primary-standout: #9acee6;
  --filter-var: invert(92%) sepia(45%) saturate(634%) hue-rotate(292deg) brightness(97%) contrast(108%);
  --drop-filter-var: #dd6f70;
}

.tomioka-theme {
  --primary-color: #663333;
  --primary-accent: #330000;
  --primary-standout: #9f7e49;
  --filter-var: invert(32%) sepia(10%) saturate(1616%) hue-rotate(93deg) brightness(93%) contrast(92%);
  --drop-filter-var: #22403e;
  /* MAIN BLUE 003333
  ALT BLUE 666666
  GOLD 996666
  dark green 336666
  light green 666666 */
}