

.buddy-outer {
    position: fixed;
    top: 125px;
    left: 5vw;
    background-color: gray;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 200px;
    min-width: 350px;
    min-height: 300px;
    
    .buddy-content {
        width: 100%;
        height: 100%;
        overflow: auto;
        scroll-behavior: smooth;
        // scrollbar-width: thin;
        scrollbar-width: thick;
        scrollbar-color: var(--scrollbar-thumb-color, #888) var(--scrollbar-track-color, #f0f0f0);

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {
            background: var(--scrollbar-track-color, #000000);
            // border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--scrollbar-thumb-color, #00c1da);
            border-radius: 8px;
            &:hover {
            background-color: var(--scrollbar-thumb-hover-color, #008a9c);
            }
        }
    }

    #terminalEditor {
        padding-bottom: 5px;
    }
}